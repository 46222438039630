
body {
    background-color: #fff;
}

h2, .h2 {
    color: #111;
    a, a:hover{
        color: inherit;
    }
}

h4, .h4 {
    color: #666;
}

.links_p a{
    color: #CF0101;
}
#footer a{
    color: #fff;
}

header {
    background: #CF0101 url(../images/imgd242series/header-bg.png) repeat-x top;
}

#headercontent h1,
#headercontent .h1 {
    color: #FFF;
}

.slogan {
    color: #FFF;
}

.menu_burger {
    background-color: #111;
    border-left: 1px solid #111;
    border-right: 1px solid #111;
}

.menu_burger {
    color: #fff;
    border-bottom: 3px solid #fff;
}

.dropDown {
    color: #fff;
}

#nav-principal {
    .navigation_link_p{
        color: #fff;
    }
    .navigation_button_p{

        &:hover, &.actif, &.sousmenu_ouvert{
            background-color: #1e1e1e;
            .navigation_link_p {
                color:#cf0101;
            }
        }
    }
    .sous_navigation_link_p{
        color: #cf0101;
    }
    .sous_navigation_button_p{
        background-color: #1e1e1e;
        &:hover, &.actif, &.sousmenu_ouvert{

            > .sous_navigation_link_p{

            }
        }
    }
}

div#menu ul li a {
    color: #FFF;
}

.wrapper.accueil_diaporama_template_1 .bx-wrapper, .wrapper.accueil_diaporama_template_3 .bx-wrapper {
    background: #CF0101;
}

.pagination > .active > a, .pagination > .active > a:hover, .pagination > .active > a:focus, .pagination > .active > span, .pagination > .active > span:hover, .pagination > .active > span:focus {
    background-color: #CF0101;
    border-color: #CF0101;
}

.pagination > li > a, .pagination > li > span {
    color: #CF0101;
}

.accueil_diaporama_template .bx-wrapper, .accueil_diaporama_template #bx-pager,
#wrapper > .content_p {
    background-color: #FFF;
}

#contentfull {
    color: #111;
}

#contentpage a, #contentfull a {
    color: #CF0101;
}

.produits {
    color: #111;
    background-color: #FFF;
    border: 1px solid #111;
}

.produits p.titreprod {
    color: #111;
    border-bottom: 1px solid #FFF;
}

.produits:hover img {
    //border:1px solid #000;
}

.galerie {
    border: 1px solid #111;
}

.galerie a {
    color: #000;
}

.galerie:hover img {
    border: 1px solid #111;
}

.message_lo p.note {
    color: #666;
}

.content_p a.addmsglo {
    background-color: #111;
    color: #FFF;
}

#addmsg input {
    background-color: #fff;
    color: #ccc;
    border: 1px solid #ccc;
}

#addmsg textarea {
    border: 1px solid #ccc;
}

#addmsg input[type=submit] {
    background-color: #000;
    border: 1px solid #ccc;
    color: #FFF;
}

#addmsg a {
    color: #000;
}

#addmsg a:hover {
    color: #AF9C78;
}

.texthautcontact {
    color: #111;
}

#contactright {
    border: 1px solid #ccc;
}

#contactleft {
    color: #111;
}

#int-contactleft {
    background-color: #FFF;
}

#form1 p {
    color: #666;
}

#form1 input, #form1 textarea {
    border: 1px solid #CF0101;
    background-color: #fff;
}

#form1 input:hover, #form1 textarea:hover {
    border: 1px solid #9b9b9b;
}

#form1 input.button, #main-conteneur .button, .side-tabs a.button {
    background-color: #111;
    color: #FFF;
}

footer {
    color: #FFF;
}

footer a {
    border-bottom: 1px solid #FFF;
    color: #FFF;
}

footer a:hover {
    border-bottom: 1px dotted #FFF;
}

footer #pub {
    background: #111 url(../images/imgd242series/header-bg.png) repeat-x top;
}

footer {
    background: #CF0101 url(../images/imgd242series/footer-bg.png) repeat-x top;
}

#pub {
    color: #fff;
}

h2 span.first-char, h4 span.first-char, .h2 span.first-char, .h4 span.first-char, .galerie a:hover {
    color: #CF0101;
}

.message_lo p.web a {
    color: #666;
}

div#menu ul li:hover {
    background: #1e1e1e;
    > a {
        color: #cf0101;
    }
}

.template_album {
    &.template_album_1 {
        .galerie {
            border-color: transparent;
            &:hover {
                border-color: #cf0101;
            }
        }
    }
}

.produits:hover, #addmsg input:hover, #addmsg textarea:hover {
    border: 1px solid #CF0101;
}

.produits:hover .produits-bottom::after, .galerie:hover .galerie-bottom::after {
    border-bottom-color: #cf0101;
}


.content_p a.addmsglo:hover, #addmsg input[type=submit]:hover, #form1 input.button:hover, #paiementchoix .button:hover, #totalbloc .button:hover {
    background-color: #CF0101;
    color: #FFF;
}

.nav-static-breadcrumb li a {
    color: rgb(209, 11, 11);
}

a.addbasket {
    color: rgb(209, 11, 11);
    background: rgba(0, 0, 0, .05);
    border: 1px solid rgba(209, 11, 11, .2);
}

.top_fiche .button.addbasket {
    i {
        border-right: 1px solid rgba(0, 0, 0, .1)
    }
}

.produits a.addbasket:hover, #fiche-produit .top_fiche .button.addbasket:hover {
    background-color: rgb(209, 11, 11);
    color: #fff;
}

.produit_etiquette:after {
    border-color: rgb(209, 11, 11) rgba(65, 129, 217, 0) rgba(65, 129, 217, 0);
}

.produit_etiquette {
    background: rgb(209, 11, 11);
}

.produit_etiquette2 {
    background: rgb(209, 11, 11);
}

.produit_etiquette2:after {
    border-color: rgba(0, 0, 0, 0) rgb(170, 3, 3) rgba(0, 0, 0, 0) rgba(0, 0, 0, 0);
}

.pagination.pull-right li.active a {
    color: #fff !important;
}

div#menu nav#nav-static-principal, #navigation-bottom::before, div#menu-bottom::before, #nav-principal {
    background: #111;
}

#bordLeft::before {
    background: radial-gradient(circle at 50% 0, rgba(0, 0, 0, 0) 215px, #111 0px);
}

.button.button-secondary {
    background: orange;

    &:hover {
        background: #444;
    }
}

#flux-panier.template_flux_panier.template_flux_panier_1 .side-tabs ul li.step-done {
    background: #CF0101;
    border-color: #CF0101;

    a {
        color: #fff;
    }
}

#flux-panier.template_flux_panier.template_flux_panier_1 .side-tabs ul li.active {
    border-color: #CF0101;
    color: #CF0101;

    a, span {
        color: #CF0101;
    }
}

#flux-panier.template_flux_panier.template_flux_panier_1 {
    color: #000;
}


.template-panier{
    .count-live{
        background:#fff;
        color:#000;
    }
}